import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  List,
  Popover,
  Space,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { API_URL, host } from "../../constants";
import axios from "axios";

const { Dragger } = Upload;
const { Text } = Typography;

let interval;

const allowedFilenames = [
  "address",
  "apartament",
  "banca",
  "calculation",
  "calcultype",
  "customers",
  "indice",
  "object",
  "region",
  "serv_apart",
  "serv_furn",
  "service",
  "street",
];

const MultiUploadContainer = () => {
  const [blockedButtons, setBlockedButtons] = useState([]);
  const [errorBtns, setErrorBtns] = useState(null);

  const handleUpload = ({ file }) => {
    if (file.status === "done") {
      message.success(`${file.name} încărcat cu succes`);
    } else if (file.status === "error") {
      message.error(`${file.name} încărcarea a eșuat.`);
    }
  };

  function handleDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  }

  function handleParsing(name) {
    try {
      axios.get(API_URL + "user/gestionar/startParse/" + name);
      interval = setInterval(checkStatus, 2000);
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  }

  const checkStatus = async () => {
    try {
      const res = await axios.get(API_URL + "user/gestionar/statusParser");
      const data = Object.keys(res.data).filter(
        (item) => res.data[item].parsing
      );

      const errors = Object.keys(res.data).filter(
        (item) => res.data[item].error
      );

      if (errors.length) {
        const errorObject = {};
        errors.forEach((e) => {
          errorObject[e] = res.data[e].error;
        });
        console.log({ errorObject });
        setErrorBtns(errorObject);
      }

      if (!data.length) {
        clearInterval(interval);
      }
      setBlockedButtons(() => {
        let arr = data.map((item) => {
          return { ...res.data[item], name: item };
        });
        return arr;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(blockedButtons);
  }, [blockedButtons]);

  useEffect(() => {
    interval = setInterval(checkStatus, 2000);
    checkStatus();

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="d-flex gap-5">
      <Dragger
        multiple
        onChange={handleUpload}
        // onRemove={handleRemove}
        onDrop={handleDrop}
        action={host + "/import/upload"}
        name="files"
        style={{ marginBottom: "16px" }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Faceți clic sau trageți fișierul în această zonă pentru a-l încărca
        </p>
        <p className="ant-upload-hint">
          Suport pentru o singură încărcare sau o încărcare în masă. Este strict
          interzisă încărcarea datelor companiei sau a altor fișiere interzise.
        </p>
      </Dragger>
      <div className="p-sm-5 p-4 d-flex w-100 flex-column gap-3 bg-white rounded">
        <p className="text-uppercase" style={{ fontWeight: "bold" }}>
          Parsing
        </p>
        <Space wrap>
          {allowedFilenames.map((filename, index) => (
            <Badge key={index} dot={errorBtns && errorBtns[filename]}>
              <Popover
                trigger={"hover"}
                title={
                  errorBtns && errorBtns[filename] ? (
                    <div style={{ maxWidth: "500px" }}>
                      <h6>
                        A apărut o eroare în înregistrarea cu CODID{" "}
                        {errorBtns && errorBtns[filename]?.item.CODID}
                      </h6>
                      <p>{errorBtns && errorBtns[filename]?.err}</p>
                    </div>
                  ) : null
                }
              >
                <Button
                  type="primary"
                  disabled={blockedButtons.length}
                  onClick={() => handleParsing(filename)}
                >
                  {filename}
                </Button>
              </Popover>
            </Badge>
          ))}
        </Space>
        {blockedButtons.length ? (
          <List
            dataSource={blockedButtons}
            size="small"
            renderItem={(item) => (
              <List.Item style={{ borderBottom: "1px solid #f0f0f0" }}>
                <Text>{item.name}</Text>
                <Text type={parseInt(item.status) > 50 ? "success" : "warning"}>
                  {item.status ?? 0}%
                </Text>
              </List.Item>
            )}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MultiUploadContainer;
